<template>
	<div class="xigua-channel">
		<el-row class="function-area">
			<el-button type="primary" size="mini" @click="showAddDialog">添加频道</el-button>
		</el-row>

		<el-table :data="xiguaChannels" style="width: 100%">
			<el-table-column prop="channelName" label="频道名称" width="150"></el-table-column>
			<el-table-column prop="running" label="主页链接" width="100">
				<template slot-scope="scope">
					<el-link :href="scope.row['channelHomeUrl']" type="primary" target="_blank">主页链接</el-link>
				</template>
			</el-table-column>
			<el-table-column prop="running" label="是否有效" width="100">
				<template slot-scope="scope">
					<el-tag v-if="scope.row['isValid']" type="success">有效</el-tag>
					<el-tag v-else type="warning">无效</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
			<el-table-column prop="updateTime" label="更新时间" width="180"></el-table-column>
			<el-table-column label="操作" width="100">
				<template slot-scope="scope">
					<el-button @click.native.prevent="showEditDialog(scope.$index)" type="text" size="small">修改</el-button>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog :title="addOrEdit === 'ADD' ? '添加频道' : '修改频道'" :visible.sync="addOrEditDialog">
			<el-form :model="addOrEditFormData" :rules="addOrEditFormRules" ref="addForm" label-width="110px" size="mini">
				<el-form-item label="频道名称" prop="channelName">
					<el-input v-model="addOrEditFormData.channelName" placeholder="请输入任务名称"></el-input>
				</el-form-item>
				<el-form-item label="主页链接" prop="channelHomeUrl">
					<el-input v-model="addOrEditFormData.channelHomeUrl" placeholder="请输入主页链接"></el-input>
				</el-form-item>
				<el-form-item label="是否有效" prop="isValid">
					<el-switch v-model="addOrEditFormData.isValid" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-input v-model="addOrEditFormData.note"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="toAddOrEditTask('addForm')" :loading="addOrEditLoading">确 定</el-button>
					<el-button @click="toResetForm">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import {getAllXiGuaChannels, addNewXiGuaChannel, updateXiGuaChannel} from "@/api/channel";

export default {
	name: "XiGuaChannel",
	data() {
		return {
			xiguaChannels: [],

			addOrEdit: "",
			addOrEditFormData: {
				id: null, channelName: null, channelHomeUrl: null, isValid: false, note: null
			},
			addOrEditFormRules: {
				channelName: [
					{required: true, message: '请输入频道名称', trigger: 'blur'},
					{min: 2, max: 15, message: '长度在2到15个字符', trigger: 'blur'}
				],
				channelHomeUrl: [{required: true, message: '请输入频道主页', trigger: 'blur'}]
			},
			addOrEditDialog: false,
			addOrEditLoading: false
		}
	},
	created() {
		this.toGetAllXiGuaChannels();
	},
	methods: {
		toGetAllXiGuaChannels() {
			getAllXiGuaChannels().then(response => {
				if (!response['success']) return this.$message.error('获取西瓜频道失败：' + response.data);
				this.xiguaChannels = response.data;
			}).catch((error) => {
				this.$message.error('获取西瓜频道失败：' + error);
			});
		},
		showAddDialog() {
			this.addOrEdit = "ADD";
			this.addOrEditDialog = true;
		},
		showEditDialog(index) {
			this.addOrEdit = "EDIT";
			this.addOrEditDialog = true;
			this.addOrEditFormData = JSON.parse(JSON.stringify(this.xiguaChannels[index]));
		},
		toAddOrEditTask(formName) {
			this.$refs[formName].validate((valid) => {
				if (!valid) return false;

				this.addOrEditLoading = true;
				if (this.addOrEdit === 'ADD') {
					addNewXiGuaChannel(this.addOrEditFormData).then(response => {
						this.addOrEditLoading = false;
						if (!response['success']) return this.$message.error('添加频道失败：' + response.data);
						this.$message.success('添加频道成功');
						this.toGetAllXiGuaChannels();
						this.toResetForm();
					}).catch((error) => {
						this.addOrEditLoading = false;
						this.$message.error('添加频道失败：' + error);
					});
				} else {
					updateXiGuaChannel(this.addOrEditFormData).then(response => {
						this.addOrEditLoading = false;
						if (!response['success']) return this.$message.error('修改频道失败：' + response.data);
						this.$message.success('修改频道成功');
						this.toGetAllXiGuaChannels();
						this.toResetForm();
					}).catch((error) => {
						this.addOrEditLoading = false;
						this.$message.error('修改频道失败：' + error);
					});
				}
			});
		},
		toResetForm() {
			// 重置form
			this.addOrEditFormData = {
				id: null, channelName: null, channelHomeUrl: null, isValid: false, note: null
			};
			this.addOrEditDialog = false;
		},
	}
}
</script>

<style lang="less" scoped>
.xigua-channel {
	margin: 10px;

	.function-area {
		margin: 10px 0;
	}
}
</style>